<template>
  <div class="pa-2">
    <v-subheader>Utility</v-subheader>
    <v-divider class="pa-2"></v-divider>

    <CardList
      :cards="registriesUtility"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          @click="handleListClick(card)"
          class="border-default mx-2 my-2 d-flex flex-column"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
          <div class="text-center text-h6 pt-2 mx-6" @click.stop.prevent="handleListClick(card)">
            {{ card.text }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large @click.stop.prevent="handleListClick(card)">
              {{ card.icon }}
            </v-icon>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-end">
            <v-btn icon class="mr-2" @click.stop.prevent="handleListClick(card)">
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn
              icon 
              class="mr-2" 
              @click.stop.prevent="handlePlusClick(card)" 
              :disabled="isLicenseBlocked"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </CardList>
    <v-subheader>Settings</v-subheader>
    <v-divider class="pa-2"></v-divider>
    <CardList
      :cards="registriesSetting"
      identifier-key="key"
      :finishLoading="true"
      show-load-more-in="none"
      :cardsClickable="true"
    >
      <template v-slot:card="{ card }">
        <v-card
          @click="handleListClick(card)"
          class="border-default  mx-2 my-1 d-flex flex-column"
          dark
          :disabled="isOnlyWheelOfFortune"
        >
          <div class="text-center text-h6 pt-2 mx-6" @click.stop.prevent="handleListClick(card)">
            {{ card.text }}
          </div>
          <div
            style="height: 100px"
            class="flex-grow-1 d-flex justify-center align-center"
          >
            <v-icon x-large @click.stop.prevent="handleListClick(card)">
              {{ card.icon }}
            </v-icon>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-end">
            <v-btn icon class="mr-2" @click.stop.prevent="handleListClick(card)">
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn 
              v-if="!!card.newRoute && (
                (card.name == 'serviceTypes' && !!currentUser && !!currentUser.system) ||
                (card.name == 'serviceTypesCategories' && !!currentUser && !!currentUser.system)
                || (card.name != 'serviceTypes' && card.name != 'serviceTypesCategories' && card.name != 'interests'))"
              icon 
              class="mr-2" 
              @click.stop.prevent="handlePlusClick(card)" 
              :disabled="isLicenseBlocked"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </CardList>
  </div>
</template>

<script>
import { CardList, SearchWithButton } from "likablehair-ui-components";
import RecentOperationsResults from "@/components/areas/registries/search/RecentOperationsResults.vue";

import registriesManagement from "@/services/registries/registries.service.js";

export default {
  name: "RegistriesHome",
  components: {
    CardList,
    SearchWithButton,
    RecentOperationsResults,
  },
  data: function() {
    return {
      registriesList: [],
      recentOperations: [],
      finalSearchText: undefined,
      viewBooking: undefined,
      isLicenseBlocked: undefined,
      isOnlyWheelOfFortune: undefined,
      currentUser: {},
    };
  },
  mounted() {
    this.loadRegistries();
    this.loadRecentOperation();
  },
  methods: {
    loadRegistries() {
      registriesManagement.list().then(data => {
        this.registriesList = data;
      });
    },
    loadRecentOperation() {
      registriesManagement.lastRecentOperationsList().then(data => {
        this.recentOperations = data;
      });
    },
    handleListClick(registry) {
      this.openDialogRoute(registry.listRoute);
    },
    handlePlusClick(registry) {
      this.openDialogRoute(registry.newRoute);
    },
    openDialogRoute(routeName) {
      this.$router.push({
        name: routeName,
        props: {
          openDialog: true
        }
      });
    }
  },
  computed: {
    registriesUtility() {
      return this.registriesList.filter(el => {

        return [
          'customers',
          'suppliers',
          'operators',
          'services',
        ].includes(el.name)
      })
    },
    registriesSetting() {
      return this.registriesList.filter(el => {
        return [
          'serviceTypesCategories',
          'serviceTypes',
          'enablingTypeCategories',
          'enablingTypes',
          'packageTypes',
          'unitOfMeasures',
          'jobs',
          'qualifications',
          'colorLines',
          'interests'
        ].includes(el.name)
      })
    },
  },
  watch: {
  },
  licenseSettings: {
    viewBooking: {
      bind: "viewBooking"
    }
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  },
  isOnlyWheelOfFortune: {
    bind: 'isOnlyWheelOfFortune'
  },
  currentUser: {
    bind: 'currentUser'
  },
};
</script>

<style scoped>
.border-default {
  padding: 5px;
  border-radius: 10px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1);
  background-image: linear-gradient(rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));
  width: 200px;
  height: 190px;
  background-color: white;
}
.border-default:hover {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  transition: all 0.2s;
  transform: scale(1.05);
  z-index: 10;
}
</style>