class RegistryNamesMappings {
  constructor() {
    this.nameMappings = {
      // many
      'operators': 'Operatori',
      'services': 'Servizi',
      'customers': 'Clienti',
      'suppliers': 'Aziende',
      'barServices': 'Servizi Bar',
      'enablingTypeCategories': 'Categorie Tipi Abilitazione',
      'enablingTypes': 'Tipi Abilitazione',
      'serviceTypes': 'Famiglie S.',
      'serviceTypesCategories': 'Reparti S.',
      'services': 'Servizi',
      'departments': 'Dipartimenti',
      'packageTypes': 'Tipi Confezioni',
      'unitOfMeasures': 'Unità Di Misura',
      'jobs': 'Professioni',
      'hairTypes': 'Tipo Di Capelli',
      'qualifications': 'Qualifiche',
      'colorLines': 'Tipi Colorazione',


      // one
      'operator': 'Operatore',
      'service': 'Servizio',
      'customer': 'Cliente',
      'supplier': 'Azienda',
      'barService': 'Servizo Bar',
      'enablingTypeCategory': 'Categoria Tipi Abilitazione',
      'enablingType': 'Tipo Abilitazione',
      'itemType': 'Famiglia',
      'serviceType': 'Famiglia S.',
      'serviceTypesCategory': 'Reparto S.',
      'service': 'Servizio',
      'department': 'Dipartimento',
      'job': 'Professione',
      'hairType': 'Tipo Di Capelli',
      'hairColor': 'Colore Di Capelli',
      'qualifications': 'Qualifica',
      'colorLine': 'Tipo Colorazione',
      'interests': 'Interessi',
    }

    this.iconRegistriesMappings = {
      // many
      'operators': 'mdi-account-group',
      'services': 'mdi-chair-rollin',
      'customers': 'mdi-shopping-outline',
      'suppliers': 'mdi-dolly',
      'barServices': 'mdi-glass-cocktail',
      'enablingTypeCategories': 'mdi-ballot-outline',
      'enablingTypes': 'mdi-badge-account-outline',
      'serviceTypes': 'mdi-chair-rolling',
      'serviceTypesCategories': 'mdi-folder-table',
      'services': 'mdi-room-service',
      'departments': 'mdi-home-city',
      'jobs': 'mdi-briefcase',
      'hairTypes': 'mdi-hair-dryer-outline',
      'qualifications': 'mdi-clipboard-account',
      'colorLines': 'mdi-invert-colors',
      'interests': 'mdi-heart-outline',

      // one
      'operator': 'mdi-account-group',
      'service': 'mdi-chair-rollin',
      'customer': 'mdi-shopping-outline',
      'supplier': 'mdi-dolly',
      'barService': 'mdi-glass-cocktail',
      'enablingTypeCategory': 'mdi-ballot-outline',
      'enablingType': 'mdi-badge-account-outline',
      'serviceType': 'mdi-chair-rolling',
      'serviceTypesCategory': 'mdi-folder-table',
      'service': 'mdi-room-service',
      'department': 'mdi-home-city',
      'packageType': 'mdi-package-variant',
      'unitOfMeasure': 'mdi-ruler-square-compass',
      'job': 'mdi-briefcase',
      'hairColor': 'mdi-palette',
      'hairType': 'mdi-hair-dryer-outline',
      'qualification': 'mdi-clipboard-account',
      'colorLine': 'mdi-invert-colors',
    }

    this.iconOperationsMappings = {
      // many
      'add': 'mdi-plus',
      'edit': 'mdi-pencil',
      'view': 'mdi-eye-outline',
      'change_registry_color': 'mdi-palette'
    }
  }

  getHumanName(codedName) {
    return this.nameMappings[codedName]
  }

  getCodedName(humanName) {
    return Object.keys(this.nameMappings).find(key => this.nameMappings[key] === humanName);
  }

  getRegistryIcon(registryName) {
    return this.iconRegistriesMappings[registryName]
  }

  getOperationIcon(operationName) {
    return this.iconOperationsMappings[operationName]
  }
}

export default new RegistryNamesMappings()