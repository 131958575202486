<template>
  <v-list-item two-line>
    <v-list-item-icon>
      <v-icon>{{operationIcon}}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{operation.text}} 
        <span 
          v-if="!!operation.extraText"
          class="font-weight-thin"
        >
          ({{operation.extraText}})
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-icon small>{{registryIcon}}</v-icon> fatta il 
        <DateTimeFormattor 
          v-model="operation.performedAt"
          format="dd/mm/yyyy"
          with-time
        ></DateTimeFormattor>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <div class="d-flex">
        <v-btn icon class="mr-2">
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-btn icon @click="performOperation">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import registryMapping from '@/services/registries/registryNameMappings'
import DateTimeFormattor from '@/components/common/DateTimeFormattor.vue'

export default {
  name: "RecentOperationResult",
  components: {
    DateTimeFormattor
  },
  data: function() {
    return {}
  },
  props: {
    operation: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  methods: {
    performOperation() {
      if(this.operation.params.routeName) {
        let argument = {name: this.operation.params.routeName}
        if(this.operation.params.routeParams) {
          argument.params = this.operation.params.routeParams
        }
        this.$router.push(argument)
      }                                  
    }
  },
  computed: {
    operationIcon() {
      return registryMapping.getOperationIcon(this.operation.operationName)
    },
    registryIcon() {
      return registryMapping.getRegistryIcon(this.operation.registry)
    }
  }
}
</script>

<style>

</style>